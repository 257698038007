<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <img
          src="https://ik.imagekit.io/aryraditya/ceremonyku/corner_2x_sZXnEWtgp.png?tr=w-500"
          style="position: absolute; top: 0; right: 0; width: 75vw; max-width: 400px; transform: translateY(-20%) translateX(20%); opacity: .5"
        />
        <img
          src="https://ik.imagekit.io/aryraditya/ceremonyku/floral-gold-1_2x.png?updatedAt=1633157420035?tr=w-500"
          style="position: absolute; bottom: 0; left: 0; width: 75vw; max-width: 400px; transform: translateY(30%) translateX(-20%) rotate(30deg); opacity: .7"
        />
        <div class="text-center w-100">
          <!-- <div class="title">Undangan {{ title }}</div>
          <span>from</span> -->
          <img
            src="https://ik.imagekit.io/aryraditya/ceremonyku/tony-nila/DSC_3461.JPG?updatedAt=1658937248404&tr=w-440,h-440,fo-custom,cm-extract"
            style="width: 100%; max-width: 150px; border-radius: 500%; border: 1px solid #C29513; padding: .25rem"
          />
          <div class="mb-2 name" data-aos="fade-down" data-aos-delay="200">
            {{ wedding.man.surname }}
            <span class="" style="margin-top: -.75rem; margin-bottom: -.75rem">
              &
            </span>
            {{ wedding.woman.surname }}
          </div>

          <div
            class="guest-box mb-3 d-block mx-auto"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <div class="guest-box__title">
              Kepada {{ $root.guest.title || "Bpk/Ibu/Sdr/i" }}.
            </div>
            <div
              class="guest-box__name"
              style="min-height: 31px; min-height: 3vh"
            >
              <span>{{ $root.guest.name }}</span>
            </div>

            <div class="guest-box__note">
              Tanpa mengurangi rasa hormat, kami mengundang anda untuk hadir
              pada acara pernikahan kami.
            </div>
          </div>

          <button
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio
      ref="audio"
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
    />
    <AGWHero
      overlay
      :background="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="bg-white agw-content"
      :style="{ 'margin-top': `${$root.hero.height}px`, overflow: 'hidden' }"
    >
      <AGWIntro id="intro" />

      <div class="pad-y-60">
        <AGWPawiwahan id="pawiwahan" />
        <AGWMepandes id="mepandes" class="mar-top-60" :mepandes="mepandes" />
      </div>

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />

      <Gallery class="pad-y-60" />

      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.0720952196384!2d115.20478911478415!3d-8.684694293760181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf413bd70449868ed!2zOMKwNDEnMDQuOSJTIDExNcKwMTInMjUuMSJF!5e0!3m2!1sen!2sid!4v1658935790423!5m2!1sen!2sid"
        directionUrl="https://goo.gl/maps/h1Q7B7kapukmTCjD9"
        :location="location"
        :time="time"
        id="map"
      />
      <a
        href="https://goo.gl/maps/h1Q7B7kapukmTCjD9"
        class="items-center justify-content-center"
        style="padding: 1rem 2rem; text-transform: uppercase; text-decoration: none; letter-spacing: .25rem; background: #C29513; color: #fff; width: 100%; display: flex; text-align: center"
      >
        <i class="material-icons mr-2">directions</i>
        Buka Peta
      </a>
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <div :style="{ height: `${$root.dividerHeight}px` }"></div>

    <Footer ref="footer" />

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/tony-nila/DSC_3483.JPG?updatedAt=1658936298707&tr=w-595,h-790,fo-custom,cm-extract";
const ImageHero2 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/tony-nila/DSC_3411.JPG?updatedAt=1658936353589&tr=w-596,h-793,fo-custom,cm-extract";
// const ImageHero3 = 'https://ik.imagekit.io/crk/adek-suja/IMG_4793.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1645882209746&tr=w-1024'

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Himbauan from "@/components/Himbauan.vue";
import Attendance from "@/components/Attendance";
import Footer from "@/components/Footer/Ceremonyku.vue";

import { setInterval } from "timers";
import Gallery from "./components/Gallery.vue";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
    Footer,
    Gallery
  },
  data() {
    return {
      title: "Pawiwahan",
      backgrounds: [ImageHero1, ImageHero2],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      wedding: {
        man: {
          name: "Kadek Tonny Yoga Parwitha",
          surname: "Tony",
          parents: "Ketut Wita (Alm) & Ni Made Suparwati",
          description: "Putra kedua",
          address: "Jl. Pulau Saelus No. 88. Br. Pande, Pedungan, Denpasar",
          photo:
            "https://ik.imagekit.io/aryraditya/ceremonyku/tony-nila/DSC_3420.JPG?updatedAt=1658935891497&tr=w-400,h-501,fo-custom,cm-extract"
        },
        woman: {
          name: "Ni Made Sri Nilawati. SH",
          surname: "Nila",
          parents: "I Nyoman Sudiarta & Ni Wayan Kenten",
          address: "Jl. Pulau Saelus Gg 5 No. 9. Br. Pande, Pedungan, Denpasar",
          description: "Putri kedua",
          photo:
            "https://ik.imagekit.io/aryraditya/ceremonyku/tony-nila/DSC_3545.JPG?updatedAt=1658935935302&tr=w-453,h-568,fo-custom,cm-extract"
        }
      },
      location: "Jl. Pulau Saelus No. 88. Br. Pande, Pedungan, Denpasar",
      time: {
        start: this.$moment("2022-08-10 15:00"),
        end: "Selesai"
      },
      mepandes: [
        { name: "I Putu Rezha Parwitha" },
        { name: "Kadek Tonny Yoga Parwitha" }
      ],
      music: {
        file: Audio,
        autoplay: true,
        loop: true
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false
      }
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    }
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // if(navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("controllerchange", () => {
    //   if (this.sw.refreshing) return;
    //   // this.sw.refreshing = true;
    //   // window.location.reload();
    // });
    // }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
      this.sw.refreshing = true;
      window.location.reload();
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  }
};
</script>
